import React, { useState, useCallback, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import { Page, WhiteBox } from 'components';
import {
  allCheckInsSelector,
  checkinsLoadingSelector,
  checkinsTotalSelector,
} from 'redux/selectors/checkin';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { checkinActions } from 'redux/reducers/checkIn';
import CheckinsFilters from './checkinsFilters';
import CheckinsTable from './checkinsTable';
import { useDidUpdateEffect } from 'utils/hooks';
import { DateRange } from './dateFilter';
import { SortOrder } from 'types/common';
import Typography from '@mui/material/Typography';
import {
  CHECKINS_PLACE_FILTER,
  CHECKINS_SEARCH_VALUE,
  CHECKINS_RANGE_FILTER,
  CHECKINS_CALENDAR_FILTER_NAME,
  CHECKINS_SORT_DIRECTION,
  CHECKINS_SORT_BY,
} from 'utils/storageKeys';
import { DateFilterOption } from 'types/experiences';

const FILTER_OPTIONS: DateFilterOption[] = [
  {
    key: 'today',
    label: 'Today',
  },
  {
    key: 'tomorrow',
    label: 'Tomorrow',
  },
  {
    key: 'week',
    label: 'This week',
  },
  {
    key: 'all',
    label: 'All',
  },
];

export type OptionType = {
  label: string;
  value: string;
};
const CheckIn: React.FC = () => {
  const dispatch = useAppDispatch();
  const checkins = useAppSelector(allCheckInsSelector);
  const loading = useAppSelector(checkinsLoadingSelector);
  const total = useAppSelector(checkinsTotalSelector);

  const searchValueStorage = sessionStorage.getItem(CHECKINS_SEARCH_VALUE);
  const placeStorage = sessionStorage.getItem(CHECKINS_PLACE_FILTER);
  const sortByStorage = sessionStorage.getItem(CHECKINS_SORT_BY);
  const dateRangeStorage = sessionStorage.getItem(CHECKINS_RANGE_FILTER);
  const activeFilterNameStorage = sessionStorage.getItem(CHECKINS_CALENDAR_FILTER_NAME);

  const sortOrderStorage = sessionStorage.getItem(CHECKINS_SORT_DIRECTION) as SortOrder;

  const [offset, setOffset] = useState(0);
  const [date, setDate] = useState<DateRange | null>(
    dateRangeStorage ? JSON.parse(dateRangeStorage) : null
  );
  const [searchValue, setSearchValue] = useState(searchValueStorage || '');
  const [place, setPlace] = useState(placeStorage || 'all');
  const [sortBy, setSortBy] = useState<string | null>(sortByStorage || 'checkinDate');
  const [sortDirection, setSortDirection] = useState<SortOrder | null>(
    sortOrderStorage || 'desc'
  );

  const sort = `&sort[${sortBy}]=${sortDirection}`;

  const onPlaceChange = useCallback((place: string) => {
    setPlace(place);
    sessionStorage.setItem(CHECKINS_PLACE_FILTER, place);
  }, []);

  const onDateChange = (date: DateRange) => {
    setDate(date);
    sessionStorage.setItem(CHECKINS_RANGE_FILTER, JSON.stringify(date));
  };

  const onSearchChange = useCallback((searchText: string) => {
    setSearchValue(searchText);
    sessionStorage.setItem(CHECKINS_SEARCH_VALUE, searchText);
  }, []);

  useEffect(() => {
    if (!checkins?.length) {
      setOffset(0);
      dispatch(
        checkinActions.getCheckIns({
          query: searchValue,
          placeId: place === 'all' ? '' : place,
          date,
          sort,
        })
      );
    }
    return () => {
      dispatch(checkinActions.updateOffset({ offset: 0 }));
    };
  }, [dispatch]);

  useDidUpdateEffect(() => {
    setOffset(0);
    dispatch(
      checkinActions.getCheckIns({
        query: searchValue,
        placeId: place === 'all' ? '' : place,
        date,
        offset: 0,
        sort,
      })
    );
  }, [date, place, sort, sortDirection, sortBy, searchValue]);

  const loadMore = () => {
    dispatch(
      checkinActions.getCheckIns({
        query: searchValue,
        placeId: place === 'all' ? '' : place,
        date,
        offset: offset + 10,
        sort,
      })
    );
    setOffset(offset + 10);
  };

  useDidUpdateEffect(() => {
    dispatch(checkinActions.updateOffset({ offset: offset }));
  }, [offset]);

  const onSortHandler = useCallback((orderBy: string, order: SortOrder) => {
    setSortBy(orderBy);
    setSortDirection(order);
    sessionStorage.setItem(CHECKINS_SORT_BY, orderBy);
    sessionStorage.setItem(CHECKINS_SORT_DIRECTION, order);
  }, []);

  return (
    <Page title="Check-ins">
      <Stack direction="row" marginTop={2}>
        <WhiteBox width="100%" height="fit-content">
          <Stack
            direction="row"
            justifyContent="space-between"
            borderBottom="1px solid rgb(247, 247, 255)"
            paddingBottom="20px"
          >
            <Typography variant="h2">Total: {total}</Typography>
            <CheckinsFilters
              onSearchChange={onSearchChange}
              onPlaceChange={onPlaceChange}
              onDateChange={onDateChange}
              searchValue={searchValue}
              place={place}
              options={FILTER_OPTIONS}
              defaultOption={
                activeFilterNameStorage
                  ? JSON.parse(activeFilterNameStorage)
                  : FILTER_OPTIONS.find((o) => o.key === 'all')
              }
            />
          </Stack>
          <CheckinsTable
            loading={loading}
            checkins={checkins || []}
            total={total}
            loadMore={loadMore}
            order={sortDirection}
            orderBy={sortBy}
            onSort={onSortHandler}
          />
        </WhiteBox>
      </Stack>
    </Page>
  );
};

export default CheckIn;
