import { Room } from 'types/places';

export enum SubscriptionVisistsEnum {
  NONE = 'none',
  SPECIFIC_AMOUNT = 'specific_amount',
  UNLIMITED = 'unlimited',
}
export const SubscriptionVisistsEnumValues = {
  [SubscriptionVisistsEnum.NONE]: 'None',
  [SubscriptionVisistsEnum.SPECIFIC_AMOUNT]: 'Specific amount',
  [SubscriptionVisistsEnum.UNLIMITED]: 'Unlimited',
};

export type SubscriptionSettings = {
  subscriptionId: string;
  visitsRestrictionType: SubscriptionVisistsEnum;
  visitsRestrictionAmount?: number;
};

export type DailyPassSettings = {
  dailyPassAllowed: boolean;
  stripeProductId?: string;
  stripePriceId?: string;
  price?: number;
  currency?: string;
  checkoutLink?: string;
};

export type Place = {
  _id: string;
  name: string;
  code?: string;
  description: string;
  address: string;
  membersOnly: boolean;
  type: string;
  city: City;
  rooms: Room[];
  tags: string[];
  isReservationEnabled: boolean;
  capacityLimit: number;
  image: string;
  photos: string[];
  availability: string[];
  dailyPassSettings: DailyPassSettings;
  subscriptionSettings: SubscriptionSettings[];
  notifyEmails: string[];
  contactEmail: string;
  contactName: string;
};

export type City = {
  _id: string;
  name: string;
  code: string;
  country: Country;
  timeZone: string;
};

export type Country = {
  _id: string;
  name: string;
  code: string;
  currency: string;
};

export enum PlaceTypes {
  ACCESS_PARTNER = 'access_partner',
  HOUSE = 'house',
  VENUE = 'venue',
}

export enum PlaceTags {
  WIFI = 'wifi',
  PRIVATE_ROOMS = 'private rooms',
  POOL = 'pool',
  SHOWERS = 'showers',
  COFFEE = 'coffee',
  FOOD = 'food',
  GOOD_FOR_MEETINGS = 'good for meetings',
}

export enum DaysOfWeekEnum {
  MONDAY = 'monday',
  TUESDAY = 'tuesday',
  WEDNESDAY = 'wednesday',
  THURSDAY = 'thursday',
  FRIDAY = 'friday',
  SATURDAY = 'saturday',
  SUNDAY = 'sunday',
}

export const placeTypesOptions = [
  { value: PlaceTypes.ACCESS_PARTNER, label: 'Access partner' },
  { value: PlaceTypes.HOUSE, label: 'House' },
  { value: PlaceTypes.VENUE, label: 'Venue' },
];

export const placeTagsOptions = [
  { value: PlaceTags.COFFEE, label: 'COFFEE' },
  { value: PlaceTags.FOOD, label: 'FOOD' },
  { value: PlaceTags.GOOD_FOR_MEETINGS, label: 'GOOD FOR MEETINGS' },
  { value: PlaceTags.POOL, label: 'POOL' },
  { value: PlaceTags.PRIVATE_ROOMS, label: 'PRIVATE ROOMS' },
  { value: PlaceTags.SHOWERS, label: 'SHOWERS' },
  { value: PlaceTags.WIFI, label: 'WIFI' },
];
